import { HL } from "../components/Misc";
import Navbar from "../components/Navbar";
import "./Contact.css"


export default function Contact() {
    return(
        <div>
            <Navbar />
            <div className="Contact">
                <h1>CONTÁCTANOS</h1>
                <HL />
                <h2>De momento puedes hacerlo enviándonos tus preguntas en cualquiera de estas plataformas.</h2>
                <div className="Contact-list">
                    <ContactElement 
                        type="email" 
                        link="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mcontrol@tuta.io" 
                        content="mcontrol@tuta.io" 
                    />
                    <ContactElement 
                        type="instagram" 
                        link="#" 
                        content="(COMING SOON...)" 
                    />
                    <ContactElement 
                        type="twitter" 
                        link="#" 
                        content="(COMING SOON...)" 
                    />
                </div>
            </div>
        </div>
    );
}

function ContactElement(props) {
    return(
        <a 
            className="Contact-element" 
            target="popup"
            href={ props.link }
        >
            <ContactIcon type={ props.type } /> : { props.content } 
        </a>
    );
}

function ContactIcon(props) {
    return <div className="Contact-icon" type={ props.type } />
}