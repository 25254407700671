import React from "react";
import { Link } from "react-router-dom";
import "./Home.css"

export function Home() {
    return(
        <div className="Home">
            <div className="Home-logo">
                <div className="Home-logo-text">
                    MCONTROL
                    <div className="Home-logo-text-sec">TECH</div>
                </div>
                <div className="logo Home-logo-icon" />
            </div>
            <h1>¡SE VIENEN COSITAS!</h1>
            <h2>Pronto os actualizaremos.</h2>
            <div className="Home-links">
                <Link className="Home-link" to="/about">SOBRE NOSOTROS</Link>
                <Link className="Home-link" to="/contact">CONTÁCTANOS AQUÍ</Link>
            </div>
        </div>
    );
}