import React from "react";
import { Link } from "react-router-dom";
import './Navbar.css';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            extended: false,
            windowWidth: window.innerWidth
        };
    }
    
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };
    
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    } 

    render() {
        return(
            <div className="Navbar">
                <div className="Navbar-background" />
                <div className="Navbar-contents">
                    <div className="Navbar-header">
                        <div className="Navbar-logo">
                            <div className="Navbar-logo-text">
                                MCONTROL
                                <div className="Navbar-logo-text-sec">TECH</div>
                            </div>
                            <div className="logo Navbar-logo-icon" />
                        </div>
                        <button 
                            type={ this.state.extended ? "close" : "open" } 
                            className="Navbar-menu-icon" 
                            onClick={
                                () => {
                                    this.setState({
                                        extended: !this.state.extended
                                    })
                                }
                            }
                        />
                    </div>
                    {
                        this.state.extended || this.state.windowWidth > 1250 ?
                        <div className="Navbar-links">
                            <NavbarLink name="INICIO" to="" />
                            <NavbarLink name="EQUIPO" to="about" />
                            <NavbarLink name="CONTACTO" to="contact" />
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}

function NavbarLink(props) {
    return(
        <Link className="Navbar-link" to={ `/${ props.to }` }>
            { props.name }
        </Link>
    );
}