import { Routes, Route } from 'react-router';
import './App.css';
import { About } from './routes/About';
import Contact from './routes/Contact';
import { Home } from './routes/Home';

function App() {
  return (
    <div className="App">
      <div className="App-background" />
      <Routes>
        <Route exact path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/contact" element={ <Contact /> } />
      </Routes>
    </div>
  );
}

export default App;
