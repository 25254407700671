import { HL } from "../components/Misc";
import Navbar from "../components/Navbar";
import "./About.css";

export function About() {
    return (
        <div>
            <Navbar />
            <div className="About">
                <h1>CONOCE AL EQUIPO</h1>
                <HL />
                <h2>Somos un equipo de cuatro ingenieros cualificados con la motivación para crear nuevos dispositivos y aplicaciones.</h2>
                <h2>Nuestro objetivo es unir nuestros conocimientos, desde circuitos hasta front-end, e ir completando <div className="highlight">nuestra idea</div>.</h2>
            </div>
        </div>
    );
}